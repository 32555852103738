<template>
  <input :value="modelValue" @input="updateInput" class="input" type="text">
</template>

<script>
export default {
  name: 'my-input',
  props: {
    modelValue: [String, Number]
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>

<style scoped>
.input {
  /* width: 100%; */
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border: 0px;
  /* border-bottom: 2px white solid; */
  /* padding: 10px 15px; */
  /* margin-top: 15px; */
}

</style>
