<template>
  <div class="info-grid-container">
    <div class="leftside">
      <Tomb :tomb="tomb" />
      <div class="stat">
        <div class="stat__item">
          <img src="img/flower.png" alt="" class="icon_l">
          <div class="number">{{ tomb.likes }}</div>
        </div>
        <div class="stat__item">
          <img src="img/visitors.svg" alt="" class="icon_l">
          <div class="number">{{ tomb.visitors }}</div>
        </div>
      </div>
      <p class="tech-info">Добавлено: {{ formatAddedDate }}</p>
      <p v-if="tomb.added_at !== tomb.updated_at" class="tech-info">Изменено: {{ formatUpdatedDate }}</p>
      <p class="tech-info">Пользователь: {{ tomb.user_id }}</p>
    </div>
    <div class="rightside">
      <h2 class="card__name">{{ tomb.name }}</h2>
      <p v-if="tomb.aliases" class="card__alias">({{ tomb.aliases }})</p>
      <h2 class="card__years">{{ tomb.year_start }}-{{ tomb.year_end }} ({{ tomb.year_total }})</h2>
      <h3>О компании</h3>
      <p class="card__descr">{{ tombDet ? tombDet.description : '...' }}</p>
      <h3>История</h3>
      <p class="card__descr">{{ tombDet ? tombDet.story : '...' }}</p>
      <template v-if="tombDet && tombDet.features">
        <h3>Характеристики</h3>
        <p class="features">
        <ul>
          <li v-for="(value, key) in tombDet.features" :key="key">
            <strong>{{ key }}:</strong> {{ value }}
          </li>
        </ul>
        </p>
      </template>

    </div>
  </div>
</template>

<script>
import Tomb from "@/components/Tomb";
import axios from "axios";


export default {
  components: { Tomb },
  props: {
    tomb: {
      type: Object,
      required: true
    }

  },
  data() {
    return {
      tombDet: undefined,
    }
  },
  methods: {
    async fetchTombDetail() {
      try {
        console.log('Fetching tomb details:');
        const response = await axios.get('/tombs/id/' + this.tomb.id);
        console.log(response)
        this.tombDet = response.data;
      } catch (e) {
        console.error('Failed get tomb details:', e);
      } finally {
      }


    },
  },
  mounted() {
    this.fetchTombDetail();

  },
  computed: {
    formatAddedDate() {
      const date = new Date(this.tomb.added_at);
      // const dateOptions = { timeZone: 'UTC', year: 'numeric', month: '2-digit', day: '2-digit' };
      // const formattedDate = date.toLocaleString('ru-RU', dateOptions);
      return date.toLocaleDateString()
    },
    formatUpdatedDate() {
      const date = new Date(this.tomb.updated_at);
      return date.toLocaleDateString()
    }
  }
}


</script>

<style scoped>
.info-grid-container {
  display: grid;
  grid-template-columns: 150px 1fr;
}

.leftside {
  justify-content: center;
}

.stat {
  display: flex;
  justify-content: space-around;

}

.stat__item {
  display: flex;
  align-items: center;

}

.icon_l {
  height: 25px;
  margin: 3px;
  /* display: flex; */

}

.number {
  /* display: flex; */
}

.rightside {
  justify-content: center;
  margin: 10px;
  padding: 5px;

  width: 90%;
  overflow-wrap: break-word;
  /* border: 1px solid red; */
}

.card__name {
  text-align: center;
}

.card__alias {
  text-align: center;
  font-size: 0.8em;
  font-style: oblique;

}

.card__years {
  text-align: center;
}

.card__descr {
  margin-bottom: 20px;
  padding: 5px;
  /* width: 90%; */
  border: 1px solid gray;
}

.features {
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid gray;
}

.features>ul {
  list-style-type: none;
  /* Убираем стандартные маркеры у списка ul */

}

.tech-info {
  font-size: 0.8em;
  font-style: oblique;

}

.tomb {
  background-image: url("../../public/img/tomb.png");
  background-repeat: no-repeat;
  height: 150px;
  width: 125px;
  padding: 10px 20px 20px 25px;
  justify-content: center;
  overflow: hidden;
  /* border: white 1px solid; */
}

.tomb__logo {
  height: 25px;
  display: flex;
  margin: 0px auto;
}

.tomb__flowers__item:nth-child(odd) {
  transform: rotate(-10deg);
}

.tomb__flowers__item:nth-child(even) {
  transform: rotate(10deg);

}

.tomb__flowers {
  height: 25px;
  justify-content: center;
  display: flex;
  padding: 0px 5px;
  cursor: move;
  /* overflow: hidden; */
  /* margin: 5px auto; */
}

.tomb__title {
  padding: 5px;
  color: white;
  text-align: center;
  cursor: zoom-in;

}

.tomb__name {
  height: 40px;
  font-size: 0.8em;
  /* margin-left: 5px; */
}

.tomb__years {
  height: 25px;
  font-size: 0.8em;
}
</style>