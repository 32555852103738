<template>
<div class="container">
  <h2 class="header">Вот мои документы</h2>
  <form @submit.prevent="submitForm">
      <label class="input-hint" for="username">E-mail: </label>
      <input type="text" id="username" v-model="username" required><br>
      
      <label class="input-hint" for="password">Пароль: </label>
      <input type="password" id="password" v-model="password" required><br>
      
      <label for="newUser">В 1й раз </label>
      <input type="checkbox" id="newUser" v-model="isNewUser"><br>
      
      <div v-if="isNewUser">
          <label class="input-hint" for="name">Имя: </label>
          <input type="text" id="name" v-model="name" required><br>
      </div>
      <div v-if="errorMsg">{{ errorMsg }}</div>
      
      <button class="btn-submit" type="submit"><img width="20px" src="../../public/img/btn_send_message_white.png" alt=""></button><br>
      <!-- Авторизоваться через 
      <button @click="authWithGoogle"><img width="25" src="../../public/img/googleLogo.png" alt=""></button> 
      <button @click="authWithYandex"><img width="25" src="../../public/img/YandexLogo.png" alt=""></button> -->
  </form>
</div>
</template>
  
<script>
//   import Tomb from "@/components/Tomb";
import axios from 'axios';
import qs from 'qs';

  export default {
    data() {
      return {
        username: '',
        password: '',
        isNewUser: false,
        name: '',
        errorMsg: '',
      }
    },
    components: { },
    props: {
    },
    methods: {
        createNewUser(loginAfter) {
          console.log('Создаем нового пользователя: ' + this.username + ':' + this.password);
            axios({
              method: 'post',
              url: '/auth/register',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              data: qs.stringify({
                "email": this.username, 
                "password": this.password,
                "username": this.name,
                // "is_active": true,
                // "is_superuser": false,
                // "is_verified": false,
                // "role": 1
              }),
            })
            .then(response => {
              // console.log('Ответ сервера: ' + response.status);
              if (loginAfter) this.loginUser();
            }).catch(error => {
                console.error(error);
                this.errorMsg = 'Ошибка: недопустимый логин';
                return undefined;
            });            
        },
        loginUser() {
          console.log('Логинимся: ' + this.username + ':' + this.password);
          axios({
            method: 'post',
            // url: '/tombs/testpost',
            url: '/auth/login',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
              "email": this.username, 
              "password": this.password,
              // "grant_type": '',
              // "scope": '',
              // "client_id": '',
              // "client_secret": ''
            }),
          })
          .then(response => {
            console.log('OK, ответ сервера: '+response.status);
            this.errorMsg = ''
            this.$emit('login');
          }).catch(error => {
              console.error(error);
              // ERR_BAD_REQUEST
              this.errorMsg = 'Ошибка: недопустимые логин и/или пароль';
          });            
        },
        submitForm() {
            // Обработка отправки формы
          if (this.isNewUser) this.createNewUser(true)
          else this.loginUser()
          
        },
        authWithGoogle() {
            // Авторизация через Google
            console.log('Авторизация через Google');
        },
        authWithYandex() {
            // Авторизация через Яндекс ID
            console.log('Авторизация через Яндекс ID');
        }
    }
  }
        
</script>
  
<style scoped>
  button {
    /* background-image: url="../../public/img/btn_send_message_white.png"; */
    width: 25px;
    height: 25px;
    background: none;
  }
  .container {
    padding: 5px;
    justify-content: center;
    text-justify: auto;
    text-align: center;
  }

  .header {
    margin: 10px 0px;
    font-style: oblique;

  }
  .input-hint {
    /* border-bottom: 2px white solid; */
  }
  input {
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: 0px;
    border-bottom: 2px white solid;
    margin: 10px 0px;
  }
</style>
