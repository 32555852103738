import {createStore} from "vuex";
import axios from "axios";
// import {postModule} from "@/store/postModule";

export default createStore({
    state: {
        user: {
            id: 0,
            username: '',
        },
      // {
      //   id: 0,
      //   email: "eremin.oleg@gmail.com",
      //   username: "Oleg",
      //   role_id: 1,
      //   registered_at: "2024-05-16T21:19:56",
      //   is_active: true,
      //   is_verified: false,
      //   is_superuser: false
      // }
        displayOptions: {
            dialogVisible: false,
            searchBarVisible: false,
            isTombsLoading: false,
            selectedSort: 'datetime_added',
            sortOrder: -1,
            searchQuery: '',
            sortOptions: [
              {value: 'name', name: 'По названию'},
              {value: 'year_start', name: 'По дате создания'},
              {value: 'year_end', name: 'По дате смерти'},
              {value: 'datetime_added', name: 'По дате похорон'},
              {value: 'likes', name: 'По кол-ву цветов'},
            ],
      
        }
      
    },
    getters: {
        isAuth(state) {
            return state.user !== null && state.user.id > 0
        },
        userDisplayName(state) {
            return state.user ? state.user.username : 'незнакомец'
        }
    },
    mutations: {
        setUser(state, newUser) {
            state.user = newUser;
        },
        setLoading(state, bool) {
            state.isPostsLoading = bool
        },
    },
    actions: {
        async initUser({state, commit}){
            console.log("Get user by JWT from back end")
            axios.get('/users/me').then(response => {
                console.log('User: '+ JSON.stringify(response.data));
                commit('setUser', response.data)
                return response.data;
            }).catch(error => {
                console.error(error);
                return undefined;
            });
        },

        async logOutUser({state, commit}){
            axios.post('/auth/logout').then(response => {
                console.log('User log out');
                commit('setUser', {id: 0, username: ''})
            }).catch(error => {
                console.error(error);
                return undefined;
            });
        
        },

    },
    modules: {
    }
})
