<template>
  <div class="container">
    <div class="block_right">Смотрителю кладбища <br>
      Чичикову П.И<br>
      от {{ $store.getters.userDisplayName }}<br>
    </div>
    <h2 class="header">Заявление</h2>

    <p class="text">Прошу разместить на Вашем кладбище память о компании</p>
    <form @submit.prevent="submitForm">
      <div class="form-row">
        <label class="input-hint-flex" for="name">Название: <br>
          <div class="subhint">(как на камне)</div>
        </label>
        <input class="input-part-line" type="text" id="name" v-model="newTomb.name" required maxlength="20"><br>
        <!-- (как на камне) <br> -->
      </div>
      <div class="form-row">
        <label class="input-hint-flex" for="aliases">Другие названия: <br>
          <div class="subhint">(через запятую)</div>
        </label>
        <input class="input-part-line" type="text" id="aliases" v-model="newTomb.aliases"><br>
      </div>
      <div class="form-row">
        <label class="input-hint-flex" for="tags">Ключевые слова: <br>
          <div class="subhint">(через запятую)</div>
        </label>
        <input class="input-part-line" type="text" id="tags" v-model="newTomb.tags"><br>
      </div>


      <label class="input-hint" for="year_start">Годы существования: с </label>
      <input type="number" max=2025 min=1950 maxlength="4" id="year_start" v-model="newTomb.year_start">
      <label class="input-hint" for="year_end">по </label>
      <input type="number" max="2025" min="1950" maxlength="4" id="year_end" v-model="newTomb.year_end"><br>

      <label class="input-hint" for="text_life">Описание:</label><br>
      <textarea class="input-full-line" id="text_life" v-model="newTomb.description" rows="5"
        wrap="soft"></textarea><br>
      <br>
      <label class="input-hint" for="text_death">История:</label><br>
      <textarea class="input-full-line" id="text_death" v-model="newTomb.story" rows="5" wrap="soft"></textarea><br>
      <br>
      <label class="input-hint" for="features">Характеристики:</label><br>

      <div v-for="(value, key) in featuresList" :key="'f_' + key">
        <div class="form-row">
          <label class="input-hint-flex feat" :for="key">{{ value }}: </label>
          <input class="input-part-line" type="text" :id="key" v-model="newTomb.features[key]">
        </div>
      </div>

      <br>
      <div class="sign">{{ currentDate }} {{ $store.getters.userDisplayName }}</div>
      <div class="form-row right">
        <button class="btn-submit" type="submit">Отправить</button>
      </div>
    </form>


  </div>
</template>

<script>
//   import Tomb from "@/components/Tomb";
export default {
  components: {},
  data() {
    return {
      newTomb: {
        user_id: 0,
        name: '',
        aliases: '',
        tags: '',
        year_start: 2000,
        year_end: new Date().getFullYear(),
        description: "",
        story: "",
        features: {},
        // added_at: new Date(),
        // updated_at: new Date(),
        // user_id: 0,
        // likes: 0,
        // visitors: 0,
        // cu_like: false,
        // cu_visit: false,
      },
      featuresList: {
        "official_site": "Сайт",
        "youtube_channel": "YouTube канал",
        "indusrty": "Индустрия",
        "region": "Страна/регион",
        "founder": "Основатель",
        "CEO": "CEO",
        "people": "Сотрудников",
        "revenue_mrub": "Выручка (млн. руб.)",
      }
    }
  },
  computed: {
    currentDate() {
      let d = new Date()
      return d.toLocaleDateString();
    },
    currentYear() {
      let d = new Date()
      return d.getFullYear();
    }

  },
  props: {
  },
  methods: {
    submitForm() {
      console.log('Submitting form from AddTomb.vue')
      console.log(this.newTomb);
      this.$emit('mySubmit', this.newTomb);

    }
  },
  mounted() {
    // console.log('Mounting AddTomb dialog. User is ' + this.$store.state.user)
    // this.newTomb.user_id = this.$store.state.user.id

  }
}
</script>

<style scoped>
.container {
  min-height: 100vh;
  background-color: white;
  color: black;
  padding: 20px 40px;
  margin: 0px;
  font-style: oblique;
}

input,
select {
  background-color: rgba(0, 0, 0, 0);
  /* color: white; */
  border: 0px;
  border-bottom: 2px black solid;
  margin: 10px 0px;
}

.form-row {
  display: flex;
  align-items: center;
}

.input-hint-flex {
  flex: 2;
  padding-right: 10px;
  /* Расстояние между элементами */
  font-weight: bold;
}

.subhint {
  font-weight: normal;
  font-size: 0.7rem;
}

.input-part-line {
  flex: 5;
  width: 100%;
  /* Занимать все доступное пространство */
  /* border: 1px solid red; */
}

.input-full-line {
  width: 100%;
  height: fit-content;
  /* height: 10cap; */
}

input[type=number] {
  width: fit-content;
  text-align: center;
}

.block_right {
  text-align: left;
  margin-left: 60%;
  margin-bottom: 40px;
}

.header {
  text-align: center;
  margin-bottom: 40px;
}

.text {
  margin-bottom: 10px;
}

.input-hint {
  font-weight: bold;
}

.feat {
  font-size: smaller;
}

.sign {
  text-align: right;
  margin: 40px 20px 0px 0px;

}

.btn-submit {
  padding: 10px 15px;
  margin-top: 10px;
  background: black;
  text-align: center;
  width: 200px;
  color: white;
  font-style: oblique;
  font-size: 16px;
  border: 1px solid teal;
  border-radius: 10px;
  cursor: pointer;
}

.btn-submit:hover {
  border: 1px solid #FD6E07;
}

.right {
  justify-self: right;
  justify-content: right;
}
</style>