<template>
  <transition appear name="slide">
    <div class="tomb">
      <div class="info" :title="ShortInfo" @click="$emit('info', tomb)">
        <!-- <div class="info" :title="ShortInfo" @click="tombDetails=true"> -->
        <img v-if="tomb.logo" class="tomb__logo" :src="'img/logos/' + tomb.logo" alt="logo">
        <img v-else class="tomb__logo" src="img/def_logo.svg" alt="logo">
        <p class="tomb__title tomb__name" v-html="formattedName"></p>
        <p class="tomb__title tomb__years">{{ tomb.year_start }}-{{ tomb.year_end }}</p>
      </div>
      <div class="tomb__flowers" @click="$emit('addLike', tomb)" :title="tomb.likes">
        <template v-for="n in flowersSpec.iter" :key="n">
          <template v-if="n == flowersSpec.med">
            <img v-if="tomb.cu_like" class="tomb__flowers__item" src="img/flower.png" alt="flowers">
            <img v-else class="tomb__flowers__item" src="img/flower_bw.png" alt="flowers">
          </template>
          <template v-else>
            <img class="tomb__flowers__item" :src="flowersSpec.img" alt="flowers">
          </template>
        </template>
      </div>

    </div>
  </transition>
</template>

<script>
export default {
  name: "tomb",
  emits: ["addLike", "info"], // <--- add this line    
  props: {
    tomb: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      flowersInBunch: 10,
      flowersInBunch2: 100,
      flowersInBunch3: 1000,
    }
  },
  computed: {
    formattedName() {
      return this.tomb.name.split(' ').map(word => {
        return word.length > 10 ? word.slice(0, 10) + '<br>' + word.slice(10) : word;
      }).join(' ');
    },

    ShortInfo() {
      return this.tomb.name + ' (' + this.tomb.year_start + '-' + this.tomb.year_end + ')'
    },
    otherLikes() {
      return this.tomb.likes - (this.tomb.cu_like ? 1 : 0);
    },
    flowersSpec() {
      var spec = {}
      if (this.otherLikes < this.flowersInBunch) {
        spec = {
          iter: this.otherLikes + 1,
          med: Math.trunc((this.otherLikes) / 2 + 1),
          img: "img/flower.png"
        }
      } else if (this.otherLikes < this.flowersInBunch2) {
        spec = {
          iter: Math.min(Math.ceil(this.otherLikes / 25), 4) + 1,
          med: Math.trunc((Math.min(Math.ceil(this.otherLikes / 25), 4)) / 2 + 1),
          img: "img/flowers.png"
        }
      }
      else {
        spec = {
          iter: Math.min(Math.trunc(this.otherLikes / this.flowersInBunch2), 2) + 1,
          med: Math.trunc((Math.min(Math.trunc(this.otherLikes / this.flowersInBunch2), 2)) / 2 + 1),
          img: "img/flowers2.png"
        }
      }
      // console.log(spec)
      // console.log(spec.iter)
      return spec
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.tomb {
  background-image: url("../../public/img/tomb.png");
  background-repeat: no-repeat;
  height: 150px;
  width: 125px;
  padding: 10px 20px 20px 25px;
  justify-content: center;
  overflow: hidden;
  /* border: white 1px solid; */
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 2.0s ease;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}

.tomb__logo {
  height: 25px;
  max-width: 70px;
  display: flex;
  margin: 0px auto;
  border-radius: 20px;
  border: gray solid 1px;
  /* opacity: 50%; */

}

.info {
  cursor: url("../../public/img/eye.svg") 10 10, default;
  user-select: none;
}

.tomb__flowers__item:nth-child(odd) {
  /* transform: rotate(-10deg); */
}

.tomb__flowers__item:nth-child(even) {
  /* transform: rotate(10deg); */

}

.tomb__flowers {
  height: 25px;
  justify-content: center;
  display: flex;
  padding: 0px 5px;
  /* cursor: grab; */
  cursor: url("../../public/img/flower.cur") 10 5, default;
  /* overflow: hidden; */
  /* margin: 5px auto; */
}

.tomb__title {
  padding: 5px;
  color: white;
  text-align: center;
  /* cursor: zoom-in; */

}

.tomb__name {
  height: 40px;
  font-size: 0.8em;
  /* margin-left: 5px; */
}

.tomb__years {
  height: 25px;
  font-size: 0.8em;
}
</style>