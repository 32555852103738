<template>
  <!-- Sidebar  -->
  <div v-show="!sidebarVisible" class="menu">
    <img width="40" src="img\oldman.png" alt="Меню" @click="sidebarVisible = true">
  </div>
  <div v-show="sidebarVisible" class="sidebar">
    <img class="btn_back" width="40" src="img\btn_back_white.svg" alt="Меню" @click="sidebarVisible = false">
    <div class="oldman">
      <img width="240" src="img\oldman.png" alt="Смотритель" @click="sidebarVisible = false">
    </div>
    <div class="oldmanMessage">Добро пожаловать, <strong>{{ $store.getters.userDisplayName }}</strong>, чего желаешь?
    </div>
    <div class="navbar">
      <ul class="list-unstyled components">
        <!-- <div @click="$router.push('/')"><strong>Vue 3</strong></div> -->
        <li><my-button @click="showDialog('about')">Что за место?</my-button></li>
        <li v-if="!$store.getters.isAuth"><my-button @click="showDialog('login')">Представиться</my-button></li>
        <li><my-button @click="showSearchBar()">Найти</my-button></li>
        <li v-if="$store.getters.isAuth"><my-button @click="showDialog('add')">Похоронить</my-button></li>
        <li><my-button @click="showDialog('donate')">Пожертвовать</my-button></li>
        <li v-if="$store.getters.isAuth"><my-button @click="userLogout">Выйти</my-button></li>
      </ul>
    </div>
    <my-dialog v-model:show="dialogVisible">
      <about-dialog v-if="dialogNameVisible === 'about'"></about-dialog>
      <login-dialog v-if="dialogNameVisible === 'login'" @close="dialogVisible = false"
        @login="userLoggedIn"></login-dialog>
      <add-dialog v-if="dialogNameVisible === 'add'" @mySubmit="createTomb"></add-dialog>
      <donate-dialog v-if="dialogNameVisible === 'donate'"></donate-dialog>
    </my-dialog>
    <!-- <my-dialog v-model:show="tombDetails">
        <tomb-id-info :tombId="3" />

      </my-dialog> -->

  </div>
</template>

<script>
import MyDialog from "@/components/UI/MyDialog";
import TombIdInfo from "@/components/TombIdInfo";
import aboutDialog from "@/components/About";
import loginDialog from "@/components/Login";
import addDialog from "@/components/AddTomb";
import donateDialog from "@/components/Donate";
import AddTomb from "./AddTomb.vue";

export default {
  components: {
    MyDialog,
    TombIdInfo,
    aboutDialog,
    loginDialog,
    addDialog,
    donateDialog
  },
  data() {
    return {
      sidebarVisible: false,
      dialogVisible: false,
      dialogNameVisible: '',
      tombDetails: false,
    }
  },
  props: {
  },
  computed: {
    // isDialogVisible() {
    //   return this.dialogNameVisible !== '';
    // }
  },
  methods: {
    showDialog(name) {
      this.dialogVisible = true;
      this.dialogNameVisible = name;

    },
    showTombDetails() {
      this.tombDetails = true;

    },
    showSearchBar() {
      this.$emit('showSearchBar')
      // emit change variable in App.vue
    },
    createTomb(newTomb) {
      this.dialogVisible = false;
      this.$emit('createTomb', newTomb);
    },
    userLoggedIn() {
      this.dialogVisible = false;
      this.$emit('login');

    },
    userLogout() {
      this.$store.dispatch('logOutUser');

    }
  }
}
</script>

<style scoped>
.menu {
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 3;
}

.btn_back {
  position: fixed;
  top: 0px;
  left: 200px;
}

.sidebar {
  height: 100vh;
  width: 240px;
  /* float: left; */
  background-color: #1B211F;
  position: sticky;
  top: 0;
  /* overflow-y: auto;   */
  background-image: url(../../public/img/bg_menu.png);
  background-size: cover;
  padding: 0px;
  justify-self: center;
  z-index: 3;

}

.container {
  position: sticky;
  top: 0;

}

.oldmanMessage {
  /* padding: 0px 15px; */
  margin: 15px;
  background-color: teal;
  border: 1px #FD6E07 solid;
  border-radius: 10px;
  color: white;
  text-align: center;
  font-size: 20px;

}

.navbar {
  justify-content: center;
  display: flex;
}

.list-unstyled {
  list-style-type: none;
  /* Убираем стандартные маркеры у списка ul */

}

@media screen and (max-width: 660px) {
  .sidebar {
    position: fixed;

  }
}
</style>
