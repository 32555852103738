<template>
  <form @submit.prevent="submitForm">
  <div class="container">
    <div class="flex-row">
    <div class="filter-block">
      <div class="filter-block__item">
        <input type="text" id="searchQuery" v-model="searchQuery" placeholder="Искать....">
      </div>
      <div class="filter-block__item">
        <select id="searchIn" name="searchIn" v-model="searchIn" >
          <option v-for="(value, key) in searchOptions" :key="key" :value="key">{{ value }}</option>
        </select> 
      </div>
    </div>
    <div class="filter-block">
      <div class="filter-block__item">
        <label for="yearStartFrom" class="input-hint">Создана с </label>
        <input type="number" max=2025 min=1950 maxlength="4" id="yearStartFrom" v-model="yearStartFrom">
        <label class="input-hint" for="yearStartTill">по </label>
        <input type="number" max="2025" min="1950" maxlength="4" id="yearStartTill" v-model="yearStarTill"><br>

      </div>
      <div class="filter-block__item">
        <label for="yearEndFrom" class="input-hint">Закрыта с </label>
        <input type="number" max=2025 min=1950 maxlength="4" id="yearEndFrom" v-model="yearEndFrom">
        <label class="input-hint" for="yearStartTill">по </label>
        <input type="number" max="2025" min="1950" maxlength="4" id="yearEndTill" v-model="yearEndTill"><br>
      </div>
    </div>
    <div class="filter-block">
      <div class="filter-block__item">
        <select id="sortBy" name="sortBy" title="Сортировать по">
          <option v-for="(value, key) in sortOptions" :key="key" :value="key">{{ value }}</option>
        </select> 
      </div>
      <div class="filter-block__item">
        <label for="sortReverseOrder">В обратном порядке </label>
        <input type="checkbox" id="sortReverseOrder" v-model="sortReverseOrder"><br>
        
      </div>
    </div>
  </div>
    <div class="flex-row btns">
      <button class="btn-hide" type="reset" @click="$emit('close')"><img width="15px" src="../../public/img/btn_close_white.svg" alt=""></button>
      <button class="btn-submit" type="submit"><img width="15px" src="../../public/img/btn_send_message_white.png" alt=""></button>
    </div>
  </div>
</form>
    
</template>
  
<script>
  import myInput from "@/components/UI/MyInput";
  export default {
    components: { myInput},
    data() { 
      return {
        searchOptions: {
          byName: "в названии", 
          byDescr: "в описании", 
          byFeature: "в характеристиках", 
        },
        sortOptions: {
          name: "по названию",
          dateStart: "по дате создания",
          dateEnd: "по дате смерти",
          dateCreated: "по дате похорон",
          visitNumber: "по кол-ву визитов",
          flowerNumber: "по кол-ву цветов",
        },
        // searchQuery: "",
        // searchIn: "byName",
        // yearStartFrom: 1950,
        // yearStartTill: 2025,
        // yearEndFrom: 1950,
        // yearEndTill: 2025,
        // sortReverseOrder: false
      }
    },
    props: {
    }
  }
</script>
  
<style scoped>
  .container {
    background: rgba(27, 33, 31, 0.9); 
    background: center url(../../public/img/bg_cemetery.png) fixed;
    background-size: cover;
    color: white;
    border-radius: 12px;
    /* height: 100px; */
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  input, select {
    background-color: rgba(255, 255, 255, 0);
    color: white;
    border: 0px;
    border-bottom: 2px white solid;
    margin: 10px 0px;
  }
  select {
    /* enable-background: ; */
  }
  option {
    background-color: white;
    color: black;
  }
  .flex-row {
    display: flex;
    align-items: center;
    width: 100%;
    /* border: solid red 2px; */
    justify-content: space-around;
    margin-bottom: 0px;
  }
  .btn-submit, .btn-hide {
    padding: 5px 5px;
    /* margin-top: 10px; */
    background: black;
    text-align: center;
    /* width: 200px; */
    color: white;
    font-style: oblique;
    font-size: 16px;
    border: 1px solid teal;
    border-radius: 10px;
    cursor: pointer; 
  }
  .btn-submit:hover, .btn-hide:hover {
      border: 2px solid #FD6E07;
  }
  .btns {
    /* justify-self:  */
    justify-content: space-between;
    margin: 10px;
  }

  .btn-hide {
  }
  .filter-block {
    margin: 0px 5px;
  }
  .filter-block__item {
  }

</style>