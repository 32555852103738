<template>

  <div v-if="tombs.length > 0">
    <div class="tomb-list">
        <tomb v-for="tomb in tombs" :tomb="tomb" :key="tomb.id" @addLike="$emit('addLikeTop', tomb)" @info="showDetails"/>
    </div>
  </div>  
  <h2 v-else class="empty">
      Пусто
  </h2>
  <my-dialog v-model:show="showTombDetails">
    <tomb-id-info :tomb="tombDetails" />
  </my-dialog>

  
</template>

<script>
import Tomb from "@/components/Tomb";
import tombIdInfo from "@/components/TombIdInfo";

export default {
  name: "tomb-list",
  emits: ["addLikeTop"], // <--- add this line    
  components: { Tomb, tombIdInfo },
  props: {
    tombs: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      showTombDetails: false,
      tombDetails: undefined
    }
  },
  methods: {
    showDetails(tomb) {
      console.log(tomb);
      this.tombDetails = tomb;
      this.showTombDetails = true;
      console.log("Смотрим детали могилы:\n");
      console.log(this.tombDetails);

    }
  }

}
</script>

<style scoped>

.tomb-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  justify-items: center;
  row-gap: 20px;
}

.empty {
  color: green; 
  font-size: 20vw;
  text-align: center;
}

@media screen and (max-width: 1200px) {
}


</style>