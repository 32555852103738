<template>
  <div class="app">
    <!-- <div class="container"></div> -->
    <!-- <div class="grid-container"> -->
    <navbar @showSearchBar="showSearchBar" @close="searchBarVisible = false" @createTomb="createTomb"
      @login="userLoggedIn" />
    <div class="main">
      <!-- Move to a component Search bar -->
      <div class="search-bar bg-gray-950" v-if="searchBarVisible">
        <div class="my-block">
          <div class="icon" @click="sortOrder = -1 * sortOrder">
            <img v-if="sortOrder === 1" src="/img/icon_sortOrderAsc.svg" alt="Сортировать по возрастанию">
            <img v-else src="/img/icon_sortOrderDsc.svg" alt="Сортировать по убыванию">
          </div>
          <my-select v-model="selectedSort" :options="sortOptions" />
        </div>
        <div class="my-block">
          <img class="icon" src="/img/icon_search.svg" alt="Искать">
          <my-input v-model="searchQuery" placeholder="Поиск...." v-focus @keypress.enter="fetchTombs(true)" />
          <img class="icon" src="/img/btn_refresh_white.svg" alt="Обновить" @click="fetchTombs(true)">
        </div>
        <div class="my-block" id="tomb_number">
          <img class="icon" src="/img/icon_tomb_white.png" alt="Количество компаний">
          {{ this.totalTombs }}
        </div>
      </div>
      <!-- <div v-if="!searchBarVisible" class="entrance-table"> -->
      <transition appear name="myappear">
        <div class="entrance-table">
          <h1 class="table-h1 pangolin-regular">Кладбище<br>компаний</h1>
        </div>
      </transition>
      <div v-if="!isTombsLoading">
        <tomb-list :tombs="sortedAndSearchedTombs" @addLikeTop="addLike" />
        <div v-intersection="loadMoreTombs" class="observer"></div>
      </div>
      <h2 v-else class="msg">Идет загрузка...</h2>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import Navbar from "@/components/Navbar";
import SearchBar from "@/components/SearchBar";
import TombList from "@/components/TombList";
import axios from 'axios';
import MySelect from "@/components/UI/MySelect";
import MyInput from "@/components/UI/MyInput";
// import VueJwtDecode from 'vue-jwt-decode'

export default {
  components: {
    Navbar,
    TombList,
    SearchBar
  },
  data() {
    return {
      tombs: [],
      dialogVisible: false,
      searchBarVisible: false,
      isTombsLoading: false,
      selectedSort: 'id',
      sortOrder: -1,
      searchQuery: '',
      offset: 0,
      limit: 30,
      totalTombs: 0,
      sortOptions: [
        { value: 'name', name: 'По названию' },
        { value: 'year_start', name: 'По дате создания' },
        { value: 'year_end', name: 'По дате смерти' },
        { value: 'added_at', name: 'По дате похорон' },
        { value: 'likes', name: 'По кол-ву цветов' },
      ],
    }
  },
  methods: {
    async createTomb(tomb) {
      console.log('Creating new tomb: ' + JSON.stringify(tomb));
      try {
        const response = await axios.post('/tombs/add',
          JSON.stringify(tomb), {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
        console.log(response)
        // this.tombs = response.data;
        // this.tombs.push(tomb);
      } catch (e) {
        console.error('Can not create new tomb:', e);
      } finally {
      }
    },

    removeTomb(tomb) {
      this.tombs = this.tombs.filter(p => p.id !== tomb.id)
    },
    addLike(tomb) {
      tomb.likes += (tomb.cu_like ? -1 : 1);
      tomb.cu_like = !tomb.cu_like;
      if (this.$store.getters.isAuth) {
        axios.get('/tombs/like', {
          params: {
            tomb_id: tomb.id,
            like: tomb.cu_like
          }
        }).then(response => {
          console.log(response.data);
        }).catch(error => {
          console.error(error);
          tomb.likes += (tomb.cu_like ? -1 : 1);
          tomb.cu_like = !tomb.cu_like;

        });
      }


    },
    showDialog() {
      this.dialogVisible = true;
    },
    showSearchBar() {
      this.searchBarVisible = !this.searchBarVisible
    },
    async getTombs() {
      try {
        const response = await axios.get('/tombs/list', {
          params: {
            offset: this.offset,
            limit: this.limit,
            search: this.searchQuery,
            sort_key: this.selectedSort,
            sort_rev: this.sortOrder == -1
          }
        });
        return response.data;
      } catch (e) {
        console.error('Ошибка загрузки данных:', e);
        return [];
      }
    },
    async getTombCount() {
      try {
        const response = await axios.get('/tombs/count', {
          params: {
            search: this.searchQuery,
          }
        });
        console.log('Всего могил: ' + response.data);
        return response.data;
      } catch (e) {
        console.error('Ошибка загрузки данных:', e);
        return 0;
      }
    },
    async fetchTombs(firstLoad) {
      if (firstLoad) {
        this.isTombsLoading = true;
        this.offset = 0;
        console.log('First load tomb list');
        this.totalTombs = await this.getTombCount();
        this.tombs = await this.getTombs();
        console.log(this.tombs)
        this.isTombsLoading = false;
      }
      else {
        if (this.isTombsLoading) return 0;
        if (this.offset + this.limit > this.totalTombs) return 0;
        this.offset += this.limit;
        console.log('loadMore, offset: ' + this.offset + ', limit: ' + this.limit + ', total:' + this.totalTombs);
        const moreTombs = await this.getTombs();
        // console.log(moreTombs)
        if (moreTombs) {
          this.tombs = [...this.tombs, ...moreTombs];
        }
      }
    },

    async loadMoreTombs() {
      await this.fetchTombs(false);
    },
    userLoggedIn() {
      this.$store.dispatch('initUser');
      this.offset = 0;
      this.fetchTombs();
    }
  },
  mounted() {
    // Установка базового URL для всех запросов
    axios.defaults.baseURL = window.location.origin;
    if (window.location.origin.endsWith(':8080')) {
      axios.defaults.baseURL = window.location.origin.replace(':8080', ':5000');
      // for development only
      // axios.defaults.baseURL = 'https://endup.info'
    }
    axios.defaults.baseURL += '/api'
    console.log(axios.defaults.baseURL)
    // Установка withCredentials в true для передачи куки
    axios.defaults.withCredentials = true;
    this.$store.dispatch('initUser');
    this.fetchTombs(true);

  },
  computed: {
    sortedTombs() {
      // return [...this.tombs].sort((tomb1, tomb2) => tomb1[this.selectedSort]?.localeCompare(tomb2[this.selectedSort]))
      return [...this.tombs].sort((tomb1, tomb2) => {
        if (typeof tomb1[this.selectedSort] === 'number') {
          // Сравнение для чисел
          return (tomb1[this.selectedSort] - tomb2[this.selectedSort]) * this.sortOrder;
        } else {
          // Сравнение для строк
          return tomb1[this.selectedSort]?.localeCompare(tomb2[this.selectedSort]) * this.sortOrder;
        }
      });
    },
    sortedAndSearchedTombs() {
      // return this.sortedTombs
      try {
        return this.sortedTombs.filter(tomb => tomb.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
      } catch (e) {
        console.log('Error in sortedAndSearchedTombs');
        return this.sortedTombs;
      }
    }
  },
  watch: {
    // page() {
    //   this.fetchTombs()
    // }
  }
}
</script>

<style>
.main {
  /* background-image: url(../public/img/bg_loan.jpeg);
  background-repeat: repeat; */
  flex: 1;
  /* width: 80%; */
}

.search-bar {
  /* background: center url(../public/img/bg_cemetery.png) fixed;
  background-size: cover; */

  position: sticky;
  top: 0;
  color: white;
  /* height: 100px; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}

.my-block {
  /* border: 1px red solid; */
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.icon {
  margin-right: 10px;
  cursor: pointer;
  width: 30px;
}

.entrance-table {
  width: 350px;
  height: 210px;
  /* height: auto;  */
  margin: 0px auto 20px;
  /* padding-bottom: 10px; */
  display: flex;
  justify-content: center;
  align-items: end;
  /* border: 3px solid black; */
  /* border-radius: 20px; */
  background-image: url(../public/img/entr_table.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.msg {
  color: green;
  font-size: 20vw;
  text-align: center;
}

.myappear-enter-active,
.myappear-leave-active {
  /* transition: opacity 3.0s ease-in; */
  transition: transform 1s;
}

.myappear-enter-from,
.myappear-leave-to {
  /* opacity: 0; */
  transform: translateY(-100%);
}

.table-h1 {
  color: white;
  -webkit-text-stroke: 1px black;
  /* Цвет обводки и толщина обводки */
  font-size: 3em;
  /* margin-bottom: 20px; */

}

.pangolin-regular {
  font-family: "Pangolin", cursive;
  font-weight: 400;
  font-style: normal;
}

.observer {
  height: 10px;
  background: green;
}

.app {
  margin: 0px;
  background-image: url(../public/img/bg_loan.jpeg);
  background-repeat: repeat;
  /* background-color: #1B211F; */
  display: flex;

}

/* 
320–480 пикселей — мобильные устройства;
481–768 пикселей — планшеты;
769–1024 пикселей — маленькие экраны, ноутбуки;
1025–1200 пикселей — большие экраны; 
*/
@media screen and (max-width: 660px) {
  #tomb_number {
    display: none;

  }
}
</style>
