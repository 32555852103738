<template>
  <div class="msgBox">
    <h2>Пожертвовать на развитие</h2>
    <p>
      <a href="https://www.tinkoff.ru/cf/9LLrVtxhDmN" target="_blank">
        <img class="qr_img" src="img/qr_tinkoff.png" alt="">
      </a>
    </p>
  </div>

</template>

<script>
//   import Tomb from "@/components/Tomb";
export default {
  components: {},
  props: {
  }
}
</script>

<style scoped>
h2 {
  align-self: center;
  margin-bottom: 10px;
}

.msgBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

p {
  align-self: center;
  justify-self: center;

}

.qr_img {
  width: 80%;
  max-width: 210px;
}
</style>