<template>
  <div class="dialog" v-if="show" @click.stop="hideDialog">
    <div @click.stop class="dialog__content">
      <div class="close_btn_container" @click="hideDialog"><img class="close_btn" src="img/btn_close_white.svg"
          alt="Закрыть окно"></div>
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'my-dialog',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    hideDialog() {
      this.$emit('update:show', false)
    }
  },
  mounted() {
    // console.log('dialog mounted')
  }
}
</script>

<style scoped>
.dialog {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  overflow: auto;
  z-index: 10;
}

.dialog__content {
  margin: auto;
  /* margin-left: 300px; */
  background: black;
  /* background: rgba(27, 33, 31, 0.9);  */
  /* background: center url(../../../public/img/bg_cemetery.png) fixed; */
  background-size: cover;
  color: white;
  border-radius: 12px;
  border: solid 1px #FD6E07;
  min-height: 50px;
  min-width: 350px;
  width: 80%;
  max-width: 600px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* Обеспечивает позиционирование для абсолютного элемента */
}

.close_btn_container {
  /* position: relative; */
  width: 100%;
  display: flex;
  justify-content: flex-end;
  /* Выравнивание по правому краю */
}

.close_btn {
  width: 20px;
  height: 20px;
  cursor: pointer;
  /* margin-left: auto; */
  /* position: absolute;
  right: 10px;
  top: 10px; */

}

@media screen and (max-width: 700px) {
  .dialog__content {
    width: 100%;

  }
}
</style>
