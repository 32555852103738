import { createApp } from "vue";
import App from "./App";
import "./index.css";
import components from "@/components/UI";
import directives from "@/directives";
import store from "@/store";

const app = createApp(App);

components.forEach((component) => {
  app.component(component.name, component);
});

directives.forEach((directive) => {
  app.directive(directive.name, directive);
});

app.use(store).mount("#app");

// app.use((req, res, next) => { res.setHeader("Content-Security-Policy", "frame-ancestors https://localhost https://autofill.yandex.ru/"); next(); });
