<template>
  <div class="msgBox">
    <p>Добро пожаловать на кладбище компаний - уникальное место в интернете, где хранится информация о закрытых
      компаниях и стартапах. Наша цель - создать место для медитации и саморефлексии. Здесь Вы увидете,
      как много компаний потерпело неудачу, и сможете черпать из этого силы для продолжения своего дела или начала
      нового.
    </p>
    <p>Каждый может "похоронить" здесь свою собственную компанию, чтобы сохранить о ней память. Другие участники могут
      оставить цветы в знак памяти и уважения к компании.
    </p>
    <p>Приглашаем вас присоединиться к нашему сообществу и внести свой вклад в создание этого особенного места памяти.
    </p>
  </div>

</template>

<script>
//   import Tomb from "@/components/Tomb";
export default {
  components: {},
  props: {
  }
}
</script>

<style scoped>
.msgBox {
  /* font-style: oblique; */
  font-size: 1.2em;
}

p {
  padding-bottom: 1em;
}
</style>