<template>
  <select  @change="changeOption">
    <!-- v-model="modelValue" -->
    <option disabled value="">Выберите из списка</option>
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
    >
      {{ option.name }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'my-select',
  props: {
    modelValue: {
      type: String
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    changeOption(event) {
      this.$emit('update:modelValue', event.target.value);
    }
  }
}
</script>

<style scoped>
  select {
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: none;
  }
  option {
    background-color: rgba(0, 0, 0, 1);
    color: white;
  }
</style>
